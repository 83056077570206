.table {
  width: 100%;
}

.tableHeadItem {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 10px;
  color: #929292;
  line-height: 14px;
  border-bottom: 1px solid rgba(16, 20, 23, 1);
}

.dataItem {
  padding: 10px 20px;
  color: white;
  font-size: 14px;
}