@media (max-width: 800px) {
  .container {
    display: none;
  }

  .mobileContainer {
    display: block;
    padding: 16px 16px 100px 16px;
  }
}

@media (min-width: 800px) {
  .container {
    display: block;
  }

  .mobileContainer {
    display: none;
  }
}

.subject {
  font-size: 16px;
  font-weight: 600;
}

.desc {
  font-size: 14px;
  color: #637592;
  margin-top: 10px;
}

.topPairsWrap {
  margin-top: 20px;
  background: #111318;
  padding: 16px;
  border-radius: 6px;
}

.topPairs_subject {
  font-size: 12px;
  font-weight: 500;
}

.topPairs {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  column-gap: 10px;
  row-gap: 10px;
}

.pairItem {
  border: 1px solid #637592;
  padding: 5px;
  border-radius: 6px;
  min-width: 40%;
}

.selectPairs {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.selectPairs_item {
  width: 45%;
}

.selectPairs_item_title {
  font-size: 12px;
}

.token1Input {
  margin-top: 4px;
  border: 1px solid #637592;
  background-color: transparent;
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 4px;
  outline: none;
}

.selectPairs_item_wrap {
  display: flex;
  margin-top: 5px;
}

.selectPairs_item_wrap_token {

  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  font-size: 12px;
  border: 1px solid #637592;
  border-radius: 4px;
}

.customWrap {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.customInput {
  border: 1px solid #637592;
  background-color: transparent;
  font-size: 12px;
  padding: 6px;
  border-radius: 4px;
  outline: none;
}

.detailButton {
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #637592;
  border-radius: 4px;
  padding: 6px;
  background-color: white;
  color: black;
}

.tableHeaders {
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tableHeaderItem {
  width: 30%;
  // height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #191D24;
  padding: 4px 0;
}

.tableHeaderCurrentItem {
  background-color: #191D24;
}

.tables {
  margin-top: 25px;
}

.marketItem {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid #191D24;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.marketItem_text {
  font-size: 12px;
  color: white;
}