@import url('./styles/global.css');


* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: black;
}

.radix-themes {
  width: 100vw;
  height: calc(100% - 60px);
  position: relative;
  background: black;
  overflow: hidden;
}