.container {
  background-color: '#111318';

  :global {
    .ant-modal-content {
      background-color: #111318;
    }

    .ant-modal-title {
      background-color: #111318;
      color: white;
    }
  }
}