.menus {
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;
    column-gap: 52px;
    margin-left: 87px;
    width: 100%;
}

.menuItem {
    font-weight: 500;
    font-size: 15px;
    color: #929292;
    line-height: 18px;
    text-align: left;
    font-style: normal;
    cursor: pointer;
}

.menuItem:hover {
    color: white;
}