.title {
    font-family: NeueMontreal, NeueMontreal;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 27px;
}

.descMobile {
    padding: 16px;
    color: #929292;
}

.signWrapMobile {
    padding: 5px 16px;
}

.signWrapMobile_label {
    color: #929292;
    font-size: 14px;
    margin-bottom: 10px;
}

.desc {
    font-weight: 500;
    font-size: 15px;
    color: #929292;
    line-height: 23px;
    text-align: left;
    font-style: normal;
}

.mobileButton {
    margin-left: 10px;
    border-radius: 6px;
    padding: 5px 10px
}

.footer {
    border-top: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 50px;
    column-gap: 15px;
}


.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 45px;
    color: black;
    height: 45px;
    background: #00FF8E;
    border-radius: 23px;
    cursor: pointer;
}

.cancelButton {
    background: #D8D8D8;
}

.continueButton {
    background: rgba(0, 255, 142, 1);
}

.signWrap {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
}

.signWrap_label {
    font-weight: 500;
    font-size: 10px;
    color: #929292;
    line-height: 23px;
    width: 78px;
}

.textareaWrap {
    flex-grow: 1;
    height: 60px;
    background-color: black;
    border-radius: 6px;
    padding: 10px;
}


.textareaWrap {
    flex-grow: 1;
    height: 100px;
    background-color: black;
    border-radius: 6px;
    padding: 10px;
}

.containerMobile {
    padding: 16px;
}

.textarea {
    outline: none;
    border: none;
    font-size: 12px;
    color: white;
    width: 100%;
    height: 100%;
    background-color: transparent;
    resize: none;
}

.signDesc {
    font-weight: 500;
    font-size: 10px;
    color: #929292;
    line-height: 23px;
    font-style: normal;
    margin-top: 5px;
    margin-left: 100px;
}

@media (min-width: 800px) {
    .container {
        display: block;
        width: 629px;
        padding: 14px 53px;
    }

    .wrap {
        min-width: 635px;
    }

    .footer {
        display: flex;
    }

    .mobileFooter {
        display: none;
    }

    .containerMobile {
        display: none;
    }

    .title {
        font-size: 23px;
        padding: 27px 53px 18px;
    }

    .signDesc {
        margin-left: 100px;
    }
}

@media (max-width: 800px) {
    .container {
        display: none;
    }

    .footer {
        display: none;
    }

    .containerMobile {
        display: block;
    }

    .title {
        font-size: 14px;
        padding: 16px;
    }

    .mobileFooter {
        display: flex;
        padding: 10px 16px;
        justify-content: flex-end;
    }
}