.radioItem {
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  cursor: pointer;
}

.radioLabel {
  color: rgba(146, 146, 146, 1);
}

.radioDesc {
  color: rgba($color: white, $alpha: 0.2);
  padding-left: 22px;
  font-size: 12px;
  margin-bottom: 15px;
}