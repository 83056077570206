.content {
    background: #101417;
    border-radius: 15px;
    border: 1px solid #101417;
    padding: 0;
}

.title {
    color: #FFFFFF;
    border-bottom: 1px solid black;
}