.side {
    color: white;
    height: 100%;
    max-width: 300px;
    min-width: 300px;
    border-right: 1px solid rgba(146, 146, 146, 0.3);
    padding: 0 40px;
    overflow: hidden;
    overflow-y: auto;
}

.sideItem {
    display: flex;
    align-items: center;
    column-gap: 27px;
    cursor: pointer;
    flex-grow: 1;
}

.sideItem_title {
    flex-grow: 1;
}

.rightIcon {
    width: 12px;
    height: auto;
}

.sideItemWrap {
    display: flex;
    align-items: center;
    padding: 28px 0;
    justify-content: space-between;
}


.logout {
    margin-top: 30px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@media (max-width: 800px) {
    .side {
        display: none;
    }
}