.wrap {
  
}

.titles {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 500;
    font-size: 23px;
    color: #FFFFFF;
    line-height: 27px;
  }
}

.helper {
  display: flex;
  align-items: center;
  padding: 9px;
  column-gap: 3px;
  cursor: pointer;

  img {
    width: 13px;
    height: 13px;
  }

  span {
    white-space: nowrap;
    width: 67px;
    height: 18px;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 18px;
    text-align: left;
    font-style: normal;
  }
}

.body {
  margin-top: 36px;
  background-color: rgba(16, 20, 23, 1);
  background: #101417;
  border-radius: 15px;
  padding: 18px 20px;
}

.table_subject {
  display: flex;
  justify-content: space-between;

  span {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 22px;
  }
}


.tableWrap {
  margin-top: 18px;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 5px;
}

.filterInput {
  background-color: black;
  color: white;
  outline: none;
}

