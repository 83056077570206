.home {
    height: calc(100% - 2px);
    border: 1px solid transparent;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right center;
}

.title {
    margin-top: 125px;
    font-weight: bold;
    font-size: 60px;
    color: #FFFFFF;
    line-height: 72px;
    text-align: center;
    font-style: normal;
}

.subTitle {
    font-weight: 500;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 38px;
    text-align: center;
    margin-top: 6px;
    font-style: normal;
}

.createButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 28px;
    color: white;
    background: #2B2B2B;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 25px;
    font-style: normal;
    cursor: pointer;
    margin: 20px auto 0;
}

.container_wrap {
    display: flex;
    margin: 44px auto 0;
    width: auto;
    justify-content: center;
}

.container_body {
    width: auto;
}

.buttonWrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    column-gap: 19px;
    width: auto;
}

.walletInput {
    width: 396px;
    height: 45px;
    background: #000000;
    border-radius: 5px;
    border: 0.3px solid #929292;
    outline: none;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    padding: 14px 17px;
}

.openWallet {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 18px;
    height: 45px;
    padding: 0 34px;
    background-color: white;
    cursor: pointer;
}

.openWalletText {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: center;
    font-style: normal;
}

.openWallet_icon {
    width: 13px;
    height: 13px;
}

.openInfo {
    margin-top: 19px;
    justify-content: flex-end;
    align-items: center;
    column-gap: 18px;
    display: flex;
}

.openInfo_text {
    font-weight: 500;
    font-size: 10px;
    color: #FFFFFF;
    line-height: 14px;
    font-style: normal;
}

.openInfo_tag {
    font-weight: 500;
    font-size: 7px;
    color: #FFFFFF;
    line-height: 9px;
    text-align: left;
    font-style: normal;
    height: 15px;
    background: #1A1A1A;
    border-radius: 8px;
    padding: 0 8px;
    align-items: center;
    display: flex;
}