.dropContent {
  background-color: black;
  padding: 12px;
  border-radius: 6px;
}

.dropContent>.DropdownMenuItem {
  outline: none;
}

.dropItem {
  color: white;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  padding: 10px;
}

.dropItem:hover {
  // outline: none;
  color: rgba($color: #ffffff, $alpha: 0.8);
}

.trigger {
  outline: none;
}

.trigger:hover {
  outline: none;
}

