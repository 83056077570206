.layout {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: #000;
}


.container {
    width: 100%;
    overflow: hidden;
    position: relative;
}


@media (max-width: 800px) {
    .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 800px) {
    .container {
        margin-top: 60px;
        height: calc(100% - 60px);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.container_body {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
}

.menuPage {
    position: fixed;
    z-index: 10000000;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    background-color: black;
}

.menuPage_header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 16px;
    border-bottom: 1px solid gray;
}


.menuPage_container_item {
    margin-bottom: 10px;
    padding: 16px 20px;
    font-size: 20px;
}

.currentItem {
    color: #00FF8E;
}