@tailwind base;

@tailwind components;

@tailwind utilities;

@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/violet.css';

@import url('./checkbox.css');
@import url('./dialog.css');

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 223 17.1% 8%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 217 19.2% 48%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 153 100% 50%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #464851;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-corner {
  background: #464851;
}

.ant-slider-step {
    background: #637592!important;
    border-radius: 2px;
}
.ant-slider-handle::before {
  background-color: #00ff8e!important;
  over-flow: hidden!important;
  border-radius: 50%!important;
}

.ant-slider-handle::after {
  background-color: #00ff8e!important;
  border-radius: 50%!important;
}

.ant-form-item-label > label {
  color: white!important;
}

.ant-input-number {
  background: transparent!important;
  outline: none!important;
  border: 1px solid rgba(255, 255, 255, 0.2)!important;
}

.ant-input-number > .ant-input-number-input-wrap > input{
   color: white!important;
   font-size: 12px!important;
}

.ant-input {
  background: transparent!important;
  outline: none!important;
  border: 1px solid rgba(255, 255, 255, 0.2)!important;
  color: white!important;
  font-size: 12px!important;
}

.ant-btn-primary {
  color: black;
}

.ant-select {
  background: transparent!important;
  outline: none!important;
  border: 1px solid rgba(255, 255, 255, 0.2)!important;
  color: white!important;
  font-size: 12px!important;
  border-radius: 6px!important;
}