.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 12px;
}

.button {
  color: black;
  background-color: white;
}

.helper {
  display: flex;
  align-items: center;
  padding: 9px;
  column-gap: 3px;
  cursor: pointer;

  img {
    width: 13px;
    height: 13px;
  }

  span {
    white-space: nowrap;
    width: 67px;
    height: 18px;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 18px;
    text-align: left;
    font-style: normal;
  }
}

.subject {
  font-weight: 500;
  font-size: 23px;
  color: #FFFFFF;
  line-height: 27px;
  text-align: left;
  font-style: normal;
}

.desc {
  margin-top: 11px;
  font-weight: 500;
  font-size: 12px;
  color: #929292;
  line-height: 15px;
  text-align: left;
  font-style: normal;
  margin-bottom: 18px;
}

.address {
  background: #191D24;
  border-radius: 15px;
  padding: 18px 15px;
  margin-top: 16px;
}

.address_subject {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 15px;
}



.addressType {
  font-size: 12px;
  border-radius: 2px;
  margin-left: 5px;
}

.dropActions {
  font-size: 12px;
  color: #FFFFFF;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.dropMenu {
  margin-left: 38px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
    height: 12px;
    margin-right: 12px;
  }
}

.tokenWrap {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  margin-right: 30px;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.tokenItem {
  border-radius: 5px;
  border: 1px solid rgba($color: #191D24, $alpha: 0.2);
}

.tokenSubject {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 500;
    font-size: 25px;
    color: #FFFFFF;
    line-height: 30px;
    margin-right: 68px;
  }

}

.tokenBalance {
  padding: 8px 20px;
  border-top: 1px solid rgba($color: #939393, $alpha: 0.2);
}

.tokenBalance_name {
  font-weight: 500;
  font-size: 8px;
  color: #939393;
  line-height: 10px;
}

.tokenBalance_value {
  margin-top: 3px;
  font-weight: 500;
  font-size: 23px;
  color: #FFFFFF;
  line-height: 27px;
}

.moreButton {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.createButton {
  cursor: pointer;
}

.button {
  margin-left: 10px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}

.button:hover {
  color: rgba($color: #FFFFFF, $alpha: 0.7);
  border: 1px solid rgba($color: #FFFFFF, $alpha: 0.7);
}

.pcAction {
  margin-left: 10px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}

.pcAction:hover {
  color: rgba($color: #FFFFFF, $alpha: 0.7);
  border: 1px solid rgba($color: #FFFFFF, $alpha: 0.7);
}

@media (min-width: 800px) {
  .button {
    display: none;
  }

  .balance {
    padding: 18px 36px;
  }

  .buttonWraps {
    display: flex;
    column-gap: 3px;
  }

  .mobileButtonWraps {
    display: none;
  }

  .balanceWrap {
    margin-top: 20px;
  }

  .balanceWrap>span {
    margin-right: 10px;
  }

  .tokenContainer_header {
    display: none;
  }
}



@media (max-width: 800px) {

  .pcAction {
    display: none;
  }

  .button {
    display: none;
  }

  .balance {
    padding: 15px 15px 100px;
  }

  .tokenContainer {
    background-color: #111318;
    padding: 16px 12px;
  }

  .tokenContainer_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tokenContainer_header_address {
    color: gold;
  }

  .buttonWraps {
    display: none;
  }

  .mobileButtonWraps {
    display: block;
    margin-top: 10px;
  }

  .balanceWrap {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .balanceWrap>span {
    margin-right: 10px;
  }

  .balanceWrap>span:nth-child(1) {
    font-size: 12px;
    color: #637592;
  }

}

.mobileButton {
  width: 100%;
  margin-bottom: 10px;
}