.account {
    height: 100%;
    align-items: center;
    justify-content: right;
    display: flex;
    column-gap: 12px;
}

.avatar {
    width: 15px;
    height: 15px;
}

.user {
    white-space: nowrap;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    color: #FFFFFF;
    line-height: 18px;
}

.accountWrap {
    display: flex;
    column-gap: 10px;
    align-items: center;
    padding: 8px 16px;
}

@media (max-width: 800px) {
    .menu {
        display: block;
        cursor: pointer;
    }

    .accountWrap {
        background-color: rgba(25, 29, 36, 1);
        border-radius: 6px;
    }
}

@media (min-width: 800px) {
    .menu {
        display: none;
    }

    .accountWrap {
        background-color: transparent;
    }
}