.header {
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100%);
    min-height: 60px;
    z-index: 100;
}

.logo {
    height: 26px;
    cursor: pointer;
}

.mobileHeader {
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;
}

@media (max-width: 600px) {
    .header {
        display: none;
    }

    .mobileHeader {
        display: flex;
    }

    .logo {
        width: 22px;
    }
}



@media (min-width: 600px) {
    .header {
        display: flex;
    }

    .mobileHeader {
        display: none;
    }

    .logo {
        width: 112px;
    }
}